import uniqBy from 'lodash/uniqBy';

import { ENTITY_TYPE, GENERAL_MANAGEMENT_AGREEMENT_TYPE } from 'constants/index';

export const transformAgencyDataForDisplay = ({ data, entityType }) => {
  if (!data) {
    return [];
  }

  const transformedData = data.map(item => {
    const agencyConnectionProfessions =
      entityType === ENTITY_TYPE.ORGANIZATION
        ? [
            {
              contactPersons: uniqBy(item?.agreements?.map(agreement => agreement?.contactPersons || []).flat(), 'id'),
              agreements: item?.agreements,
            },
          ]
        : item?.agencyConnectionProfessions;

    const contactPerson = agencyConnectionProfessions?.reduce((acc, acp) => {
      if (!acc) {
        const contact = acp?.contactPersons?.[0];
        if (contact) {
          return contact;
        }
      }
      return acc;
    }, null);

    const isGM = agencyConnectionProfessions.some(connection =>
      connection.agreements?.some(agreement => agreement?.agreementType === GENERAL_MANAGEMENT_AGREEMENT_TYPE),
    );

    return { ...item, isGM, contactPerson, agencyConnectionProfessions };
  });

  return transformedData.sort((a, b) => b.isGM - a.isGM);
};
