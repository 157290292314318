/* eslint-disable no-restricted-syntax */
import { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import partition from 'lodash/partition';
import {
  CONTACT_TYPE,
  PRIVACY_ATTRIBUTE_IDS,
  PRIVACY_ATTRIBUTE_PROFILE_TYPES,
  PROFILE_TYPE_IDS,
} from 'constants/index';

export const useBuildContacts = ({ contacts = [], addresses = [], profileTypeId, hasCastingToolAccess }) => {
  const { generalContacts, boxOfficeContacts } = useMemo(() => {
    const addressesList = addresses?.map(address => ({
      ...address,
      name: address?.address,
      value: address?.address,
      contactType: {
        id: 4,
        slug: CONTACT_TYPE.ADDRESS,
      },
    }));

    const mapContacts = [...contacts, ...addressesList]?.map(contact => {
      if (CONTACT_TYPE.WORK === contact?.contactType?.slug) {
        return {
          ...contact,
          href: `mailto:${contact?.value}`,
          contact_typeId: contact?.contact_type?.id,
          visibility: true,
          hasPermissionToView: true,
        };
      }
      if (CONTACT_TYPE.SNS === contact?.contactType?.slug) {
        return {
          ...contact,
          href: contact?.value,
          target: '_blank',
          contact_typeId: contact?.contactType?.id,
          ...(contact?.contactType?.slug === CONTACT_TYPE.WEBSITE &&
            contact.contactType.name && { name: contact.contactType.name }),
          visibility: true,
          hasPermissionToView: true,
        };
      }
      if ([CONTACT_TYPE.EMAIL, CONTACT_TYPE.PHONE, CONTACT_TYPE.WEBSITE].includes(contact?.contactType?.slug)) {
        const privacyAttributesIds = contact?.privacyAttributes?.map(privacy => privacy?.id);

        return {
          ...contact,
          ...(contact?.contactType?.slug === CONTACT_TYPE.EMAIL && {
            href: `mailto:${contact?.value}`,
          }),
          ...(contact?.contactType?.slug === CONTACT_TYPE.WEBSITE && {
            name: contact.contactType.name,
            target: '_blank',
            href: contact?.value,
          }),
          contact_typeId: contact?.contactType?.id,
          visibility: contact?.privacyAttributes?.[0]?.id !== PRIVACY_ATTRIBUTE_IDS.PRIVATE,
          hasPermissionToView:
            hasCastingToolAccess ||
            privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_PROFILE_TYPES[profileTypeId]) ||
            privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_IDS.PUBLIC) ||
            (privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_IDS.FREE_AND_PRO) &&
              [PROFILE_TYPE_IDS.ARTIST, PROFILE_TYPE_IDS.AGENT, PROFILE_TYPE_IDS.AOS].includes(profileTypeId)),
        };
      }
      return {
        ...contact,
        contact_typeId: contact?.contactType?.id,
        visibility: true,
        hasPermissionToView: true,
      };
    });
    const filterPrivateContacts = mapContacts?.filter(contact => contact?.visibility);
    // TODO: Clean up start with box_office
    const [boxOfficeContactsList, generalContactsList] = partition(
      filterPrivateContacts,
      contact => contact?.category === 'box_office',
    );

    const groupBoxOfficeContacts = groupBy(boxOfficeContactsList, 'contactType.slug');
    const groupGeneralContacts = groupBy(generalContactsList, 'contactType.slug');

    const sortContacts = obj => ({
      [CONTACT_TYPE.PHONE]: obj?.[CONTACT_TYPE.PHONE],
      email: obj?.email,
      website: obj?.website,
      address: obj?.address,
      sns: obj?.sns,
      other: obj?.other,
    });

    return {
      generalContacts: pickBy(sortContacts(groupGeneralContacts), identity),
      boxOfficeContacts: pickBy(sortContacts(groupBoxOfficeContacts), identity),
    };
  }, [addresses, contacts, hasCastingToolAccess, profileTypeId]);

  return { generalContacts, boxOfficeContacts };
};
