import React, { useMemo } from 'react';
import Overflow from 'rc-overflow';

import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import SectionBlock from 'components/Globals/SectionBlock';
import { COMPONENTS } from 'components/Globals/Analytics/constants';
import useTheme, { THEMES } from 'components/Globals/Layout/ThemeProvider';

import { CONTACT_TYPE, TP, ENTITY_MAIN_TABS } from 'constants/index';

import { useBuildContacts } from 'utils/contacts';
import usePageContext from 'utils/hooks/usePageContext';

import { useTranslation } from 'src/i18n';

import classes from './EntitySocialLinks.module.scss';

const ContactItem = ({ contact, linkProps, trackingData }) => {
  const { theme } = useTheme();

  return (
    <LinkButton
      variant="text"
      isLink
      {...([CONTACT_TYPE.SNS, CONTACT_TYPE.WEBSITE].includes(contact?.contactType?.slug) && {
        href: contact?.value,
        external: true,
      })}
      {...(contact?.contactType?.slug !== CONTACT_TYPE.SNS && { ...linkProps })}
      styles={{
        root: classes.inline,
      }}
      trackingData={trackingData}
    >
      <SpriteIcon icon={theme === THEMES.DARK ? `${contact?.name}_dark` : contact?.name} size={32} hover />
    </LinkButton>
  );
};

const GeneralContacts = ({ contacts, trackingData, contactTabLinkProps }) =>
  contacts?.map((contact, index) => (
    <ContactItem
      key={`${contact?.contactType?.slug}-${index}`}
      contact={contact}
      trackingData={{
        ...trackingData,
        component: COMPONENTS.CONTACT_CTA,
        meta: {
          id: contact?.name,
        },
      }}
      linkProps={contactTabLinkProps}
    />
  ));

const InlineScrollableView = ({
  entity,
  entityType,
  contacts,
  trackingData,
  showGeneralContact = false,
  maxSocialContactCount,
  showSocialLinks,
}) => {
  const { navigate } = usePageContext();
  const snsContacts = showSocialLinks
    ? [...(contacts[CONTACT_TYPE.SNS] || []), ...(contacts[CONTACT_TYPE.WEBSITE] || [])]
    : [];
  const generalContacts = useMemo(() => {
    const contactTypesToInclude = [CONTACT_TYPE.PHONE, CONTACT_TYPE.EMAIL];
    const uniqueContactsByType = showGeneralContact
      ? Object.entries(contacts)
          .filter(([contactType]) => contactTypesToInclude.includes(contactType))
          .flatMap(([, contactList]) => contactList)
          .reduce((acc, contact) => {
            if (!acc.some(existingContact => existingContact?.contactType?.slug === contact?.contactType?.slug)) {
              acc.push(contact);
            }
            return acc;
          }, [])
      : [];

    return uniqueContactsByType;
  }, [contacts, showGeneralContact]);
  const contactTabLinkProps = navigate.getLinkProps({
    entityType,
    entity,
    path: ENTITY_MAIN_TABS.CONTACT,
    isPro: false,
  });

  if (maxSocialContactCount) {
    return (
      <div className={classes.contactList}>
        <GeneralContacts
          contacts={generalContacts}
          trackingData={trackingData}
          contactTabLinkProps={contactTabLinkProps}
        />
        {generalContacts?.length > 0 && showSocialLinks && <div className={classes.divider} />}
        <Overflow
          className={classes.contactOverflowContainer}
          data={snsContacts}
          renderRest={items => (
            <LinkButton
              variant="text"
              isLink
              {...contactTabLinkProps}
              styles={{
                root: classes.contactOverflowContainer__link,
              }}
              trackingData={trackingData}
            >
              <Typography color="tertiary" size={11} weight="bold" className={classes.contactOverflowContainer__count}>
                {`+${items?.length}`}
              </Typography>
            </LinkButton>
          )}
          renderItem={(contact, index) => (
            <ContactItem
              key={`${contact?.contactType?.slug}-${index}`}
              contact={contact}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.SOCIAL_MEDIA_CTA,
                meta: {
                  socialMedia: contact?.name,
                  id: contact?.name,
                },
              }}
            />
          )}
          maxCount={maxSocialContactCount}
        />
      </div>
    );
  }

  return (
    <>
      <HorizontalScrollIndicators
        triggerSize={0}
        isArrowsEnabled={false}
        styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
      >
        <div className={classes.contactList}>
          <GeneralContacts
            contacts={generalContacts}
            trackingData={trackingData}
            contactTabLinkProps={contactTabLinkProps}
          />
          {generalContacts?.length > 0 && showSocialLinks && <div className={classes.divider} />}
          {snsContacts?.map((contact, index) => (
            <ContactItem
              key={`${contact?.contactType?.slug}-${index}`}
              contact={contact}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.SOCIAL_MEDIA_CTA,
                meta: {
                  socialMedia: contact?.name,
                  id: contact?.name,
                },
              }}
            />
          ))}
        </div>
      </HorizontalScrollIndicators>
    </>
  );
};

const EntitySocialLinks = ({
  entity,
  entityType,
  inline,
  sectionProps = {},
  trackingData,
  showGeneralContact = false,
  maxSocialContactCount,
  showSocialLinks = true,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { generalContacts } = useBuildContacts({ contacts: entity?.contacts });

  if (!Object.keys(generalContacts)?.length) {
    return null;
  }

  if (inline) {
    return (
      <InlineScrollableView
        entity={entity}
        entityType={entityType}
        contacts={generalContacts}
        trackingData={trackingData}
        showGeneralContact={showGeneralContact}
        maxSocialContactCount={maxSocialContactCount}
        showSocialLinks={showSocialLinks}
      />
    );
  }

  if (!generalContacts[CONTACT_TYPE.SNS]?.length) {
    return null;
  }

  return (
    <div>
      <div className={classes.socialLinksHeader}>
        <SectionBlock
          title={t(`${TP}.FN_TAB_DIGITAL_PRESENCE`)}
          styles={{
            title: classes.socialLinksHeaderTitle,
            header: classes.socialLinksSectionHeader,
            leftBoxIcon: classes.leftBoxIcon,
          }}
          {...sectionProps}
          seeAllTrackingData={{
            ...trackingData,
            component: COMPONENTS.SEE_ALL_CTA,
          }}
        />
      </div>
      <div className={classes.socialLinksContent}>
        {Object.keys(generalContacts)?.map(contactType => {
          const contactsList = generalContacts[contactType];

          return contactsList?.map(
            (contactItem, index) =>
              [CONTACT_TYPE.SNS, CONTACT_TYPE.OTHER].includes(contactType) && (
                <LinkButton
                  key={`${contactType}-${index}`}
                  variant="text"
                  isLink
                  external
                  href={contactItem?.value}
                  styles={{
                    root: classes.socialLink,
                  }}
                  disableUnderline
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.SOCIAL_MEDIA_CTA,
                    meta: {
                      socialMedia: contactItem?.name,
                    },
                  }}
                >
                  <div className={classes.socialLinkInfo}>
                    <SpriteIcon
                      icon={contactType === CONTACT_TYPE.SNS ? contactItem?.name : 'link'}
                      size={24}
                      className={classes.socialIcon}
                    />
                    <SpriteIcon
                      icon={contactType === CONTACT_TYPE.SNS ? `${contactItem?.name}:hover` : 'link:hover'}
                      size={24}
                      className={classes.socialHoverIcon}
                    />
                    <Typography size={14} weight="medium" variant="p" className={classes.linkName}>
                      {contactItem?.name}
                    </Typography>
                  </div>
                  <SpriteIcon className={classes.openNewIcon} icon="open_in_new" size={18} />
                </LinkButton>
              ),
          );
        })}
      </div>
    </div>
  );
};

export default EntitySocialLinks;
